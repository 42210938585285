


















































































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import AppBar from '@/components/AppBar.vue'
import axios from 'axios'

@Component({
  components: {
    AppBar,
  },
})
export default class Receipt extends Vue {
  receiptNumber = ''
  orderNumber = ''
  orderDate = ''

  orderer = ''
  proviso = ''
  coupon = true
  point = true
  dateType = 'orderDate'

  items = [
    {text: '注文日を表示', value: 'orderDate'},
    {text: '発行日を表示', value: 'today'}
  ]

  mounted() {
    this.receiptNumber = this.$route.params.id
    if(this.$route.params.orderer) {
      this.orderer = `${this.$route.params.orderer}`
    }
    this.proviso = "商品代として"

    if(this.$route.params.proviso) {
      this.proviso = `${this.$route.params.proviso}`
    }

    if(this.$route.params.coupon && this.$route.params.point) {
      this.coupon = this.$route.params.coupon === 'true'
      this.point = this.$route.params.point === 'true'
    }
    this.dateType = !this.$route.params.dateType ? 'orderDate' : `${this.$route.params.dateType}`

    axios.get(
      `/data/${this.receiptNumber}.json`
    ).then(res => {
      this.orderNumber = res.data.orderNumber
      this.orderDate = res.data.orderDate
      this.orderer = !this.$route.params.orderer ? res.data.orderer : this.$route.params.orderer
    }).catch(err => {
      this.$router.push({
        name: 'Home',
        params: {
          id: this.$route.params.id,
          notFound: 'true',
        }
      })
    })
  }

  onClickReceipt() {
    this.$router.push({
      name: 'ReceiptPrint',
      params: {
        id: this.receiptNumber,
        orderer: this.orderer,
        proviso: this.proviso,
        coupon: `${this.coupon}`,
        point: `${this.point}`,
        dateType: this.dateType
      }});
  }
}
