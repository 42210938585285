















































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import AppBar from '@/components/AppBar.vue';

@Component({
  components: {
    AppBar,
  },
})
export default class Home extends Vue {
  receiptNumber = ''
  notFound = false

  mounted() {
    if(this.$route.params.notFound) {
      this.receiptNumber = this.$route.params.id
      this.notFound = true
    }
  }

  onClickInput() {    
    this.$router.push({ name: 'Receipt', params: {id: this.receiptNumber}});
  }
}
