import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import i18n from './plugins/i18n'

Vue.config.productionTip = false

Vue.filter('numberFormat', function (value: number) {
  const result = new Intl.NumberFormat('ja-JP').format(value)
  return result
})

Vue.filter('currencyFormat', function (value: number) {
  const result = new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'JPY' }).format(value)
  return result
})

new Vue({
  router,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
