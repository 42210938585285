import Vue from 'vue';
import VueI18n from 'vue-i18n';

// Ready translated locale messages
const messages = {
  ja: {
  },
};

Vue.use(VueI18n);

// Create VueI18n instance with options
export default new VueI18n({
  locale: 'ja', // set locale
  messages, // set locale messages
});
